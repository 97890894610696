.loginBtnWrapperOffice365 {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
}
.loginBtnWrapperOffice365 * {
    font-family: sans-serif;
}

.loginBtnWrapperOffice365 .previewBtn {
    display: flex;
    align-items: center;
    box-shadow: none;
    border: 0;
    color: white;
    background: rgb(26, 130, 197);
    padding: 0 10px 0 10px;
    font-size: 14px;
    box-sizing: border-box;
    transition: 0.2s;
    border-radius: 0;
    outline: none;
    appearance: none;
}

.loginBtnWrapperOffice365 .previewBtn .previewBtn__icon {
    padding: 5px 10px 5px 0 ;
    margin-right: 10px;
    border-right: 1px solid rgba(255,255,255,0.1);


}

.loginBtnWrapperOffice365 .previewBtn .previewBtn__icon .previewBtnLogo{
    /*width: 10px;*/
    /*height: 10px;*/
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginBtnWrapperOffice365 .previewBtn .previewBtn__icon .previewBtnLogo g {
    transform: scale(0.4) translateX(10px) translateY(10px);
}

.loginBtnWrapperOffice365 .previewBtn.previewBtn--white {
    background: white;
    color:rgb(26, 130, 197);
}

.loginBtnWrapperOffice365 .previewBtn:not(.previewBtn--white) .previewBtn__icon .previewBtnLogo g polygon {
    fill: #fff !important;
}

.loginBtnWrapperOffice365 .previewBtn.previewBtn--white:hover {
    background: #e1eef3;
}
.loginBtnWrapperOffice365 .previewBtn.previewBtn--white:active {
    background: #d2e3ea;
}

.loginBtnWrapperOffice365 .previewBtn.previewBtn--white .previewBtn__icon{
    border-right-color: rgba(37, 125, 142, 0.2);
}

.loginBtnWrapperOffice365 .menuItemsWrapperOffice365 {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100%;
    background: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    margin-top: 5px;
    padding: 0;
    border: 1px solid rgb(26, 130, 197);
}


.loginBtnWrapperOffice365 .menuItemsWrapperOffice365 li {
    list-style: none;

}
.loginBtnWrapperOffice365 .menuItemsWrapperOffice365 li button{
    background: transparent;
    border: none;
    outline: none;
    appearance: none;
    font-size: 14px;
    padding: 6px 20px;
    width: 100%;
    transition: 0.2s;
    color: #444855;
}
.loginBtnWrapperOffice365 .menuItemsWrapperOffice365 li button:hover{
    background: rgba(0,0,0,0.05);
}
